
export class ChatPageNavigation {
  public static createEmpty(): ChatPageNavigation {
    // entity of chat
    return new ChatPageNavigation();
  }
  constructor(
    public ChatPageType: number = 1,
    public EntityType: number = 0,
    public EntityId: string = '',
    public ChannelHashId: string = '',
  ) { }
}
