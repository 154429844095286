
import { ApplicationColor } from '@/entities/Chat/ApplicationColor';
import { ChatPageNavigation } from '@/entities/Chat/ChatPageNavigation';
import { ClientCredential } from '@/entities/Chat/ClientCredential';
import { FetchData } from '@/main';
import { Component, toNative, Vue } from 'vue-facing-decorator';

@Component
class VueIFrame extends Vue {
  private iFrameURL =  process.env.VUE_APP_CHAT;
  private clientCredential: ClientCredential = ClientCredential.createEmpty();
  private mounted() {
    this.CreateChatUser();
    window.addEventListener('message',  this.handleMessage);
  }
  private CreateChatUser() {
    FetchData('/ApplicationInstance/CreateChatModeratorUser','')
      .then((response: any) => {
        this.clientCredential.EventId = response.EventId;
        this.clientCredential.TeamId = response.Team;
        this.clientCredential.UserToken = response.Token;
        this.clientCredential.UserId = response.User;
      })
      .catch(() => {
        // Do nothing
      });
  }

  private  handleMessage(ev: any  ){

    switch (ev.data.type) {
    case 'GetFontSize':
      this.GetFontSize();
      break;
    case 'GetCredentials':
      setTimeout(() => {
        this.GetCredentials(ev);
      }, 1000);
      break;
    case 'GetColors':
      this.GetColors(ev);
      break;
    case 'GetPageType':
      setTimeout(() => {
        this.GetPageType(ev);
      } , 1000);
      break;
    case 'PushHandle':
      this.PushHandle();
      break;
    default:
      break;
    }
  }
  private iframeSrc() {
    return this.iFrameURL;
  }

  private GetCredentials(ev: any) {
    // convert into string
    const data = JSON.stringify(this.clientCredential);
    ev.source.postMessage({ type: ev.data.type, data }, ev.origin);
  }

  private GetColors(ev: any) {
    // fill color object
    const color: ApplicationColor = ApplicationColor.createEmpty();
    const data = JSON.stringify(color);
    ev.source.postMessage({ type: ev.data.type, data }, ev.origin);
  }

  private GetPageType(ev: any) {
    const channelPage: ChatPageNavigation = ChatPageNavigation.createEmpty();
    channelPage.EntityId = this.clientCredential.UserId;
    const data = JSON.stringify(channelPage);
    ev.source.postMessage({ type: ev.data.type, data }, ev.origin);
  }
  private PushHandle() {
    // addComment
  }
  private GetFontSize() {
  // add comment
  }
}
export default toNative(VueIFrame);
