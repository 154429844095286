
export class ApplicationColor {
  public static createEmpty(): ApplicationColor {
    // entity of chat
    return new ApplicationColor();
  }
  constructor(
    public TopNavBackgroundColor: string = 'black',
    public TopNavForegroundColor: string = 'white',
  ) { }
}
